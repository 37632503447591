<template v-if="image">
	<swiper
		class="carousel-highlight"
		:pagination="pagination"
		:navigation="navigation"
		:autoplay="true"
		:loop="imagesLength"
		:watchOverflow="false"
		:allowTouchMove="imagesLength"
		:modules="modules"
	>
		<swiper-slide v-for="image in images" :key="image.id" class="slides">
			<AppLink v-if="image.button_title == undefined && image.button_url != undefined" :to="''+ image.button_url" class="link-button d-block"></AppLink>
				<template v-if="image.header_image.path.slice((image.header_image.path.lastIndexOf('.') - 1 >>> 0) + 2) != 'mp4'">
					<picture>
						<source :srcset="imagesBaseUrl + image.header_image.path" media="(min-width: 768.98px)" />
						<img
							:src="
								image.mobile_image == null
									? imagesBaseUrl + image.header_image.path
									: imagesBaseUrl + image.mobile_image.path
							"
							alt="Imagem header"
							class="carousel-highlight-image"
						/>
					</picture>
				</template>
				<template v-else>
					<video class="carousel-highlight-image" autoplay muted loop>
						<source :src="imagesBaseUrl+ image.header_image.path" type="video/mp4">
					</video>
				</template>
				<template v-if="image.category"><div class="gradient"></div></template>
				<div class="container h-100">
					<div class="row h-100">
						<div class="col-md-6 d-flex align-items-end align-items-md-center mb-5 pb-3 mb-md-0 pb-md-0">
							<div :style="'color:' + image.text_color">
								<template v-if="!image.only_image">
									<h1 v-if="image.title">{{ image.title }}</h1>
									<h5 v-if="image.subtitle">{{ image.subtitle }}</h5>
								</template>
								<!-- Receitas -->
								<template v-if="(image.category && image.preparation_time && image.yield) || image.description">
									<div class="d-flex align-items-center my-3" :style="'color:' + image.text_color">
										<div class="d-flex align-items-center">
											<img src="../assets/img/receitas/icone-tipo.svg" class="img-fluid m-auto d-block" alt="Icone Receita" />
											<p class="m-0 ms-2">{{ image.category.name }}</p>
										</div>
										<div class="d-flex align-items-center ms-3">
											<img src="../assets/img/receitas/icone-tempo.svg" class="img-fluid m-auto d-block" alt="Icone Receita"/>
											<p class="m-0 ms-2">{{ image.preparation_time }}</p>
										</div>
										<div class="d-flex align-items-center ms-3">
											<img src="../assets/img/receitas/icone-porcao.svg" class="img-fluid m-auto d-block" alt="Icone Receita"/>
											<p class="m-0 ms-2">{{ image.yield }}</p>
										</div>
									</div>
									<h5 :style="'color:' + image.text_color" v-if="image.description_show == true || image.description_show != ''">{{ image.description }}</h5>
									<router-link :to="'/receitas/'+image.slug" class="btn btn-light">{{ $t('recipes.botao') }}</router-link>
								</template>
								
								<AppLink  v-if="image.button_url" :to="''+ image.button_url" class="btn btn-primary me-3">{{image.button_title}}</AppLink>
								<AppLink  v-if="image.document" :to="imagesBaseUrl+ image.document.path" class="btn btn-primary">{{image.document_title != null ? image.document_title :  "Download" }}</AppLink>
							</div>
						</div>
					</div>
				</div>
		</swiper-slide>
		<div class="carousel-navigation" v-if="imagesLength">
			<div class="nav-indicator prevArrow">
				<img src="../assets/img/arrow-prev.svg" alt="Voltar" class="img-fluid d-block" />
			</div>
			<div class="pagination-highlight"></div>
			<div class="nav-indicator nextArrow">
				<img src="../assets/img/arrow-next.svg" alt="Avançar" class="img-fluid d-block" />
			</div>
		</div>
	</swiper>
</template>

<script>
import { usePiniaStore } from "@/store/indexPinia";
import { storeToRefs } from "pinia";
import AppLink from './AppLink.vue';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import "swiper/css";
import "swiper/css/pagination";

export default {
	name: "CarouselHighlight",
	components: {
		Swiper,
		SwiperSlide,
		AppLink,
	},
	props: {
		images: {
			type: Object,
		},
	},
	setup(props) {
		const { imagesBaseUrl } = storeToRefs(usePiniaStore());

		const imagesLength = ref(props.images.length >= 2 ? true : false);

		return {
			imagesBaseUrl,
			imagesLength,
			pagination: {
				clickable: true,
				el: ".pagination-highlight",
			},
			navigation: {
				nextEl: ".nextArrow",
				prevEl: ".prevArrow",
			},
			modules: [Autoplay, Navigation, Pagination],
		};
	},
};
</script>

<style >
.carousel-highlight {
	position: relative;
	height: 80vh;
	width: 100%;
}

.carousel-highlight-image {
	position: absolute;
	object-fit: cover;
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.carousel-highlight .carousel-navigation {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	z-index: 1;
	bottom: 70px;
}

.carousel-highlight .carousel-navigation img {
	width: 25px;
}

.carousel-highlight .nav-indicator {
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.5s;
}

.carousel-highlight .nav-indicator:hover {
	opacity: 1;
}

.carousel-highlight .swiper-pagination-bullets.swiper-pagination-horizontal {
	width: auto;
	display: flex;
	height: 11px;
}

.carousel-highlight .swiper-pagination {
	position: static;
}

.carousel-highlight .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: var(--primary-color);
	opacity: 0.4;
	margin: 0 0.4rem !important;
	line-height: 0px;
}

.carousel-highlight .swiper-pagination-bullet-active {
	opacity: 1;
}

.carousel-highlight  .gradient{
	background: linear-gradient(90deg, rgba(0,0,0,0.4374124649859944) 11%, rgba(0,0,0,0) 42%);
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.link-button{
	width: 100%;
	height: 100%;
}

@media (max-width: 767.98px) {
	.carousel-highlight {
		height: 500px;
	}
	.carousel-highlight .carousel-navigation {
		bottom: 10px;
	}
}
</style>